
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router';
import { clearAuthData, selectRoles } from '../Redux/AuthSlice';
import { hasPermission } from '../Redux/PermissionSlice';

const PrivateRoute = ({ type }) => {
    const location = useLocation();
    const token = useSelector((state) => state.auth.token);
    const productType = useSelector((state) => state.auth.type);
    const dispatch = useDispatch();

    const selectedRoles = useSelector(selectRoles);
  const userRoles = Array.isArray(selectedRoles) 
    ? selectedRoles 
    : selectedRoles?.split(',').map(role => role.trim());

    const whitelistedRoutes = [
        '/dashboard/overview',
        '/dashboard/profile' 
         
      ];
    
      const hasRequiredPermissions = 
        whitelistedRoutes.includes(location.pathname) ||
        hasPermission(userRoles, location.state?.requiredRoles || []);

        console.log(hasRequiredPermissions)
    // Define isAuthenticated and isCorrectProductType here
    const isAuthenticated = token && token !== "";
    const isCorrectProductType = productType === type;

    useEffect(() => {
        // If not authenticated or incorrect product type, clear auth data
        if (!isAuthenticated || !isCorrectProductType) {
            dispatch(clearAuthData());
        }
    }, [dispatch, token, productType, type,isAuthenticated,isCorrectProductType]);

    // Render Outlet if authenticated and correct product type, else navigate to login
    return (isAuthenticated && isCorrectProductType && hasRequiredPermissions) ? <Outlet /> : <Navigate to='/' />;
}

export default PrivateRoute;
