import React, { useEffect, useRef, useState } from 'react';
import { IoLogOutOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { clearAuthData } from '../Redux/AuthSlice';
import { CgProfile } from 'react-icons/cg';
import { FcOnlineSupport } from 'react-icons/fc';
import { api_url } from '../config';
import { toast } from 'react-toastify';
import api from '../utils/Api';
import { DNA } from 'react-loader-spinner';

const Header = ({ handleLogout }) => {

    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState('')
    const { email_verified_at } = useSelector((state) => state.auth)
    const { last_name, first_name, token } = useSelector((state) => state.auth)

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setDropdownVisible(!isDropdownVisible);
    };

    const navigateToProfile = (e) => {
        e.stopPropagation();
        navigate('/profile');
        setDropdownVisible(false);
    }
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function getInitials(name) {
        const words = name?.split(' ');
        const initials = words?.map(word => word?.charAt(0).toUpperCase());
        return initials?.join('');
    }

    const name = first_name + last_name;
    const initials = getInitials(name);



    return (
        <div className=''>
            <div className='flex justify-between items-center px-2 sm:px-8 py-1'>
                <span className='flex justify-center items-center gap-2'>
                    <FcOnlineSupport size={32} />
                    <p className='text-xl text-slate-600 tracking-wide leading-10'>
                        Customer Support
                    </p>
                </span>
                <div className='flex flex-col justify-center items-center'>
                    <div className='relative font-lufga' ref={dropdownRef}>
                        {/* <button
                            className='px-1 py-2.5 border border-black h-10 w-10 rounded-full text-xs text-center sm:text-sm cursor-pointer'
                            onClick={toggleDropdown}
                        >
                          
                        </button> */}
                        <img
                            src={`https://ui-avatars.com/api/?name=${name}&background=random`}
                            alt="Company Avatar"
                            className="h-10 w-10 rounded-full cursor-pointer hover:opacity-75"
                            onClick={toggleDropdown}
                        />
                        {isDropdownVisible && (
                            <div className='absolute flex flex-col top-12 right-0 bg-white border rounded shadow-md p-2 min-w-[150px] z-50'>
                                <button
                                    onClick={handleLogout}
                                    className='w-full text-left cursor-pointer hover:bg-slate-100 flex gap-2 items-center p-2 rounded transition-colors duration-200'
                                >
                                    <IoLogOutOutline size={22} />
                                    <span className='text-xs sm:text-sm'>Logout</span>
                                </button>
                                {/* <button
                                    onClick={navigateToProfile}
                                    className='w-full text-left cursor-pointer hover:bg-slate-100 flex gap-2 items-center p-2 rounded transition-colors duration-200'
                                >
                                    <CgProfile size={22} />
                                    <span className='text-xs sm:text-sm'>Profile</span>
                                </button> */}
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Header;
