import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UpdateDepartmentModal from './UpdateDepartmentModal';
import { api_url } from '../config';
import api from '../utils/Api';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import { fetchDepartments } from '../Redux/DepartmentSlice';
import { selectRoles } from '../Redux/AuthSlice';
import { hasPermission } from '../Redux/PermissionSlice';


const DepartmentDetails = () => {
    const { id: deptId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [department, setDepartment] = useState(null);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [updateForm, setUpdateForm] = useState({
        name: '',
        selectedUsers: []
    });
    const selectedRoles = useSelector(selectRoles);
    const userRoles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());




    const { token, id } = useSelector((state) => state.auth);
    const { departments } = useSelector((state) => state.departments);
    const fetchDepartmentDetails = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await api.get(`${api_url}/user/${id}/department/${deptId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });


            const data = response.data.data
            setDepartment(data);
            setUpdateForm({
                name: data.name,
                selectedUsers: data.users.map(user => user.id)
            });
        } catch (err) {

            toast.error('Error fetching department details')
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {
        if (deptId) {
            fetchDepartmentDetails();
        }
    }, [id, token, deptId]);
    useEffect(() => {

        dispatch(fetchDepartments({ id, token }));

    }, [dispatch, id, token]);


    // if (isLoading) {
    //     return <div className="flex items-center justify-center h-full">Loading...</div>;
    // }

    // if (error) {
    //     return <div className="flex items-center justify-center h-full text-red-500">Error: {error}</div>;
    // }

    // if (!department) {
    //     return <div className="flex items-center justify-center h-full">Department not found</div>;
    // }

    return (
        <div>
            <div className="flex items-center gap-4 mb-6">
                <button
                    onClick={() => navigate('/dashboard/departments')}
                    className="text-gray-600 hover:text-gray-800"
                >
                    ←
                </button>
                <h1 className="text-base font-semibold">Department Details</h1>
            </div>
            <div className='flex w-full'>
                <div className="w-1/3 bg-gray-50 p-2 rounded-lg shadow-md overflow-auto max-h-[80vh]">
                    <h2 className="text-lg font-semibold mb-4">Department List ({departments?.length})</h2>
                    <ul>
                        {departments.map((dept) => (
                            <li
                                key={dept.id}
                                onClick={() => navigate(`/dashboard/departments/${dept.id}`)}
                                className={`flex items-center justify-between border-b text-sm p-2 rounded-lg cursor-pointer transition-colors
                                    ${dept.id === parseInt(deptId) ? 'bg-gray-200' : 'bg-white hover:bg-gray-100'}`}
                            >
                                <span>{dept.name}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                {
                    isLoading ? <div className="h-[50vh] w-full flex justify-center items-center">
                        <ColorRing
                            visible={true}
                            height="60"
                            width="60"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                    </div>
                        :
                        <div className="p-6 mx-auto w-full">
                            <div className="bg-white rounded-lg shadow-sm p-1">
                                <div className="flex flex-col mb-3 bg-slate-200 p-4 m-2 rounded-md">
                                    <h2 className="text-lg font-medium">{department?.name}</h2>
                                    <p className="text-sm text-gray-600">Number of Users: {department?.users?.length}</p>
                                </div>
                                <div className='p-4'>
                                    <p className="text-sm text-gray-600 mb-2">User List</p>
                                    <ul className="space-y-1">
                                        {department?.users?.map(user => (
                                            <li key={user?.id} className="text-sm">• {`${user?.first_name} ${user?.last_name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                                {hasPermission(userRoles, ['departmentWriter', 'departmentAdmin']) && (
                                    <div className='flex justify-end m-3'>
                                        <button
                                            onClick={() => setIsUpdateModalOpen(true)}
                                            className="bg-blue-500 text-white text-sm py-1 px-4 rounded hover:bg-blue-600 transition-colors"
                                        >
                                            Update
                                        </button>
                                    </div>
                                )}


                            </div>
                        </div>
                }
            </div>
            <UpdateDepartmentModal
                isOpen={isUpdateModalOpen}
                onClose={() => setIsUpdateModalOpen(false)}
                department={department}
                token={token}
                fetchDepartmentDetails={fetchDepartmentDetails}
            />
        </div>
    );
};

export default DepartmentDetails;