import React, { useEffect, useState } from 'react';
import { fetchDepartments } from '../Redux/DepartmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import api from '../utils/Api'; 
import { api_url } from '../config'; 
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addBacklogIssue, fetchSubscriberBacklogs, resetAddIssueStatus, selectAddIssueError, selectAddIssueStatus } from '../Redux/SubscriberBacklogSlice';

const AddIssueForm = ({ isOpen, onClose, onSuccess }) => {
    const [issue, setIssue] = useState('');
    const [department, setDepartment] = useState('');
    const [assignee, setAssignee] = useState('');
    const [product, setProduct] = useState('');
    const [priority, setPriority] = useState('');
    const [departmentUsers, setDepartmentUsers] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const addStatus = useSelector(selectAddIssueStatus);
    const addError = useSelector(selectAddIssueError);

    const { id, token } = useSelector((state) => state.auth);
    const { departments } = useSelector((state) => state.departments);
    const {subscriberId} = useParams()

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDepartments({ id, token }));
    }, [dispatch, id, token]);

    useEffect(() => {
        if (department && departments) {
            const selectedDept = departments.find(dept => dept.uuid === department);
            setDepartmentUsers(selectedDept?.users || []);
            setAssignee('');
        }
    }, [department, departments]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const departmentId = departments.find(dept => dept.uuid === department)?.id;
        
        const issueData = {
            description: issue,
            department_id: departmentId,
            assignee_id: assignee,
            app: product,
            priority
        };

        const resultAction = await dispatch(addBacklogIssue({
            userId: id,
            subscriberId,
            issueData,
            token
        }));

        if (addBacklogIssue.fulfilled.match(resultAction)) {
            // Fetch the updated list of backlogs
            dispatch(fetchSubscriberBacklogs({ userId: id, subscriberId, token }));
            
            toast.success('Added successfully');
            // Reset form
            setIssue('');
            setDepartment('');
            setAssignee('');
            setProduct('');
            setPriority('');
            onClose();
        }

        // Reset the add issue status when component unmounts or when needed
        return () => {
            dispatch(resetAddIssueStatus());
        };
    };


    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg w-full max-w-xl mx-3 sm:mx-0">
                <h2 className="text-xl border-b p-4">Add Issue</h2>

                {error && (
                    <div className="mx-6 mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit} className='p-6'>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Issue</label>
                        <textarea
                            value={issue}
                            onChange={(e) => setIssue(e.target.value)}
                            className="border border-gray-300 rounded-md p-2 w-full"
                            rows={3}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Product</label>
                        <select
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                            className="border border-gray-300 rounded-md p-2 w-full"
                            required
                        >
                            <option value="" disabled>Select</option>
                            <option className='text-xs' value='pharmtrix-wholesale'>Pharmtrix Wholesale</option>
                            <option className='text-xs' value='pharmtrix-retail'>Pharmtrix Retail</option>
                            <option className='text-xs' value='centrix'>Centrix</option>
                            <option className='text-xs' value='billtrix'>Billtrix</option>
                            <option className='text-xs' value='labtrix'>Labtrix</option>
                        </select>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-3 gap-3'>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Department</label>
                            <select
                                value={department}
                                onChange={(e) => setDepartment(e.target.value)}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                required
                            >
                                <option value="" disabled>Select</option>
                                {departments?.map((dept) => (
                                    <option key={dept.uuid} value={dept.uuid} className="text-xs">
                                        {dept.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Assignee</label>
                            <select
                                value={assignee}
                                onChange={(e) => setAssignee(e.target.value)}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                required
                            >
                                <option value="" disabled>Select</option>
                                {departmentUsers.map((user) => (
                                    <option key={user.id} value={user.id} className="text-xs">
                                        {`${user.first_name} ${user.last_name}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Priority</label>
                            <select
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                required
                            >
                                <option value="" disabled>Select</option>
                                <option className='text-xs' value='low'>Low</option>
                                <option className='text-xs' value='medium'>Medium</option>
                                <option className='text-xs' value='high'>High</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex justify-end gap-3 mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="text-gray-500 border border-gray-300 px-4 py-2 rounded text-xs"
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded text-xs disabled:bg-blue-400"
                            disabled={isSubmitting}
                        >
                            {addStatus === 'loading'  ? 'Adding...' : 'Add Issue'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddIssueForm;