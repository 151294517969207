import React, { useEffect, useState } from 'react';
import { IoClose, IoCopyOutline } from 'react-icons/io5';
import api from '../../utils/Api';
import { api_url } from '../../config';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CiWarning } from 'react-icons/ci';
import { Hourglass, MutatingDots } from 'react-loader-spinner';

const DeleteTokenModal = ({ onClose, deleteModal, fetchTokens, selectedToken }) => {
    const [name, setName] = useState('');
    const [error, setError] = useState('')
    const [errorStatus, setErrorStatus] = useState(false)
    const [generatedToken, setGeneratedToken] = useState('')
    const { id, token } = useSelector((state) => state.auth)
    const [showToken, setShowToken] = useState(false)
    const [loading, setLoading] = useState(false)
    const [copied, setCopied] = useState(false)

    const handleDelete = async () => {
        try {
            setLoading(true)
            await api.delete(api_url + `/admin/${id}/tokens/${selectedToken}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success('Token successfully deleted')
            setTimeout(() => {
                onClose()
            }, 1500);
            fetchTokens()

        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
        } finally {
            setLoading(false)
        }
    }
    return (
        <div

            onClick={onClose}
            className={`fixed inset-0 flex justify-center items-center transition-colors ${deleteModal ? "visible bg-black/20" : "invisible"}`}>
            <div
                onClick={e => e.stopPropagation()}
                className={`max-w-md sm:w-[40vw] mx-4  sm:mx-auto bg-white rounded-xl shadow p-6 transition-all ${deleteModal ? "scale-100 opacity-100" : "scale-125 opacity-0"}`}
            >
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <MutatingDots
                            visible={true}
                            height="100"
                            width="100"
                            color="#6C48C5"
                            secondaryColor="#6C48C5"
                            radius="12.5"
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                )}
                <h2 className="text-lg font-black text-gray-800 mb-3 text-center">Confirm Delete</h2>
                <div>
                    <p className='text-xs sm:text-sm  text-center'>Are you sure you want to delete this token?</p>
                </div>
                <div className="flex items-center justify-center gap-3 my-2">
                    <button
                        onClick={() => { onClose() }}
                        className="bg-gray-500 font-lufga text-white py-1 text-xs sm:text-sm px-4 rounded-md hover:bg-gray-600 shadow-md transition duration-300">Cancel</button>
                    <button
                        onClick={handleDelete}
                        className="bg-purple-800 font-lufga text-white py-1 text-xs sm:text-sm px-4 rounded-md hover:bg-purple-600 shadow-md transition duration-300">YES</button>
                </div>
            </div>

        </div>
    );
};

export default DeleteTokenModal;