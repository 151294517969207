import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/Api';
import { api_url } from '../config';

export const fetchSubscriberBacklogs = createAsyncThunk(
    'subscriberBacklog/fetchSubscriberBacklogs',
    async ({ userId, subscriberId, token }, { rejectWithValue }) => {
        try {
            const response = await api.get(`${api_url}/user/${userId}/subscriber/${subscriberId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return response.data.data || [];
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error in fetching Backlogs');
        }
    }
);

export const addBacklogIssue = createAsyncThunk(
    'subscriberBacklog/addIssue',
    async ({ userId, subscriberId, issueData, token }, { rejectWithValue }) => {
        try {
            const response = await api.post(
                `${api_url}/user/${userId}/subscriber/${subscriberId}/backlogs`,
                issueData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            
            if (response.status === 201 || response.status === 200) {
                return response.data.data;
            } else {
                return rejectWithValue('Failed to create issue');
            }
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to create issue. Please try again.');
        }
    }
);



const subscriberBacklogSlice = createSlice({
    name: 'subscriberBacklog',
    initialState: {
        backlogs: [],
        status: 'idle', 
        error: null,
        addIssueStatus: 'idle',
        addIssueError: null
    },
    reducers: {
        clearBacklogs: (state) => {
            state.backlogs = [];
            state.status = 'idle';
            state.error = null;
        },
        resetAddIssueStatus: (state) => {
            state.addIssueStatus = 'idle';
            state.addIssueError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriberBacklogs.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchSubscriberBacklogs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.backlogs = action.payload;
                state.error = null;
            })
            .addCase(fetchSubscriberBacklogs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // Add issue cases
            .addCase(addBacklogIssue.pending, (state) => {
                state.addIssueStatus = 'loading';
                state.addIssueError = null;
            })
            .addCase(addBacklogIssue.fulfilled, (state, action) => {
                state.addIssueStatus = 'succeeded';
                if (action.payload) {
                    //here we want to refetch cos the data from the response is different from what is in the backlog
                    state.status = 'idle'; // This will trigger a re-fetch in the component
                }
                // state.backlogs.backlogs = [action.payload,...state.backlogs.backlogs];
                state.addIssueError = null;
            })
            .addCase(addBacklogIssue.rejected, (state, action) => {
                state.addIssueStatus = 'failed';
                state.addIssueError = action.payload;
            });
    }
});

export const { clearBacklogs,resetAddIssueStatus } = subscriberBacklogSlice.actions;

export const selectAllBacklogs = (state) => state.subscriberBacklog.backlogs;
export const selectBacklogStatus = (state) => state.subscriberBacklog.status;
export const selectBacklogError = (state) => state.subscriberBacklog.error;
export const selectAddIssueStatus = (state) => state.subscriberBacklog.addIssueStatus;
export const selectAddIssueError = (state) => state.subscriberBacklog.addIssueError;

export default subscriberBacklogSlice.reducer;