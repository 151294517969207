import React from 'react';

const ResolvedIssueVIew = ({issues}) => {
    
    return (
        <div>
           <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row space-x-4 w-full">
                {/* Subscriber Information */}
                <div className="bg-white p-4 rounded-lg shadow sm:w-1/3 max-h-fit">
                    <h2 className="text-lg font-semibold mb-4">Subscriber Information</h2>
                    <div className='flex flex-col gap-5'>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Product Name</p>
                            <p> {issues.app}</p>
                        </span>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Priority</p>
                            <p> {issues.priority}</p>
                        </span>
                    </div>

                </div>

                {/* Log Details */}
                <div className="bg-white p-4 rounded-lg shadow sm:w-2/3 flex-1">
                    <h2 className="text-lg font-semibold mb-2">Log Details</h2>
                    <div className="flex justify-between text-sm mb-2">

                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Issue ID</p>
                            <p> {issues?.id}</p>
                        </span>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Department</p>
                            <p> {issues?.department?.name}</p>
                        </span>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Assignee</p>
                            <p> {issues?.assignee?.name}</p>
                        </span>

                    </div>
                    <div className="bg-gray-100 p-2 rounded">
                        <strong className="text-xs text-gray-500">Issue Details</strong>
                        <p className="text-sm mt-1">{issues.description}</p>
                    </div>
                    <div className="bg-gray-100 p-2 rounded mt-2">
                        <strong className="text-xs text-gray-500">Notes</strong>
                        {/* <p className="text-sm mt-1">{issues?.description}</p> */}
                        {issues?.notes?.map((note)=>(
                            <div key={note.id} className='my-2' >
                                <p className="text-sm ">{note?.note}</p>
                                <span className='flex items-center text-xs'>created by<p className=" text-gray-500">: {note?.name}</p></span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
           

          
        </div>
    );
}

export default ResolvedIssueVIew;
