import React from 'react'

const OverviewPage = () => {
  return (
    <div className='w-full h-full max-w-4xl mx-auto flex justify-center items-center mb-10'>
        <div className='flex flex-col items-center'>
        <p>Overview</p>
        <p>Doc says we should take it off but if we do what will be the general landing page for a user since all the other features have Permissions</p>
        </div>
    </div>
  )
}

export default OverviewPage