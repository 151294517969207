import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Redux/AuthSlice';
import userReducer from './Redux/UsersSlice';
import departmentReducer from './Redux/DepartmentSlice';
import subscriberBacklogReducer from './Redux/SubscriberBacklogSlice';
import permissionReducer from './Redux/PermissionSlice'

const store = configureStore({
    reducer:{
        auth:authReducer,
        permissions:permissionReducer,
        users:userReducer,
        departments: departmentReducer,
        subscriberBacklog: subscriberBacklogReducer
    }
})

export default store;