import React, { useEffect, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';
import { CgMore } from 'react-icons/cg';
import AddDepartmentModal from '../components/AddDepartmentModal';
import { useDispatch, useSelector } from 'react-redux';
import { ImFileEmpty } from 'react-icons/im';
import { DNA } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { fetchDepartments } from '../Redux/DepartmentSlice';
import { useNavigate } from 'react-router-dom';
import { selectRoles } from '../Redux/AuthSlice';
import { hasPermission } from '../Redux/PermissionSlice';

const DepartmentsPage = () => {
    const dispatch = useDispatch();
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate()

    const { id, token } = useSelector((state) => state.auth);
    const { departments, status } = useSelector((state) => state.departments);
    const selectedRoles = useSelector(selectRoles);
    const userRoles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());


    const closeModal = () => setIsModalOpen(false);

    const toggleDropdown = (index) => setOpenDropdownIndex(openDropdownIndex === index ? null : index);

    useEffect(() => {

        dispatch(fetchDepartments({ id, token }));

    }, [dispatch, id, token]);

    return (
        <div className='h-full flex flex-col'>
            {status === 'loading' ? (
                <div className="flex justify-center items-center w-full h-full backdrop-blur-sm absolute top-0 left-0">
                    <DNA visible={true} height="80" width="80" ariaLabel="dna-loading" />
                </div>
            ) : (
                <div className='w-full h-full max-w-4xl mx-auto flex flex-col mb-10'>
                    <div className="flex justify-between items-center m-5">
                        <p className="font-semibold text-base">Departments</p>
                        {hasPermission(userRoles, ['departmentWriter', 'departmentAdmin']) && (
                            <button
                                onClick={() => setIsModalOpen(true)}
                                className="bg-blue-500 text-white text-sm py-1 px-4 rounded hover:bg-blue-600 transition-colors">
                                Add Department
                            </button>
                        )}


                    </div>
                    {departments.length > 0 ? (
                        <div className="flex-grow overflow-hidden bg-white shadow-md rounded-lg">
                            <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
                                <table className="w-full">
                                    <thead className="bg-gray-50 sticky top-0 z-10">
                                        <tr className="text-left">
                                            <th className="py-3 px-4 font-semibold text-sm text-gray-600">Department Name</th>
                                            <th className="py-3 px-4 font-semibold text-sm text-gray-600">Number of Users</th>
                                            <th className="py-3 px-4 font-semibold text-sm text-gray-600">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {departments.map((dept, index) => (
                                            <tr key={dept.id} className="border-t border-gray-200">
                                                <td className="py-2 px-4 text-xs">{dept?.name}</td>
                                                <td className="py-2 px-4 text-xs">{dept?.users?.length || 0}</td>
                                                <td className="py-2 px-4 text-xs">
                                                    <button
                                                        onClick={() => navigate(`${dept.id}`,{ 
                                                            state: { 
                                                                requiredRoles: ['departmentAdmin','departmentView','departmentWriter'] 
                                                            } 
                                                        })}
                                                        className="flex w-full items-center rounded px-3 py-1 text-xs text-blue-700 hover:underline" role="menuitem">
                                                        View 
                                                    </button>
                                                </td>
                                                {/* <td className="py-2 px-4 text-xs">
                                                    <div className="relative inline-block text-left">
                                                        <button onClick={() => toggleDropdown(index)} className="text-gray-400 hover:text-gray-600">
                                                            <CgMore size={16} />
                                                        </button>
                                                        {openDropdownIndex === index && (
                                                            <div className="absolute z-10 right-0 mt-2 w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                                                <div className="p-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                                    <button
                                                                        onClick={() => navigate(`${dept.id}`)}
                                                                        className="flex w-full items-center rounded px-3 py-1 text-xs text-gray-700 hover:bg-gray-100" role="menuitem">
                                                                        <BsEye className="mr-2 h-5 w-5 text-gray-400" /> View Department
                                                                    </button>
                                                                    <button className="flex items-center w-full rounded px-3 py-1 text-xs text-gray-700 hover:bg-gray-100" role="menuitem">
                                                                        <BiTrash className="mr-2 h-5 w-5 text-gray-400" /> Delete
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (


                        <div className="w-full h-full flex justify-center items-center">
                            {hasPermission(userRoles, ['backlogWriter', 'backlogAdmin']) && (
                                <span className="flex flex-col items-center justify-center gap-3">
                                    <ImFileEmpty size={48} className="text-blue-600" />
                                    <p className="text-xs">No departments yet.</p>
                                    <button onClick={() => setIsModalOpen(true)} className="bg-blue-500 text-white text-sm py-1 px-4 rounded hover:bg-blue-600 transition-colors">
                                        Add Department
                                    </button>
                                </span>
                            )}


                        </div>
                    )}
                    <AddDepartmentModal
                        fetchDepartments={() => dispatch(fetchDepartments({ id, token }))}
                        isOpen={isModalOpen} onClose={closeModal} token={token} />
                </div>
            )}
        </div>
    );
};

export default DepartmentsPage;
