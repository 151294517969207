import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ChevronDown } from 'lucide-react';
import api from '../utils/Api';
import { api_url } from '../config';
import { useSelector } from 'react-redux';
import { Switch } from '@headlessui/react';

const EditUserForm = ({ isOpen, onClose, token, fetchUser, userData }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [departments, setDepartments] = useState([]);
    const [isDepartmentsOpen, setIsDepartmentsOpen] = useState(false);
    const [isPermissionsOpen, setIsPermissionsOpen] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [formData, setFormData] = useState({
        email: '',
        first_name: '',
        last_name: '',
        departments: [],
        roles: [],
        status: 'active'
    });

    const { id } = useSelector((state) => state.auth);

    const roles = [
        {
            label: 'Subscriber Management',
            permissions: [
                { value: 'subscriberViewer', label: 'View subscribers' },
                { value: 'subscriberWriter', label: 'Manage subscribers' },
            ],
        },
        {
            label: 'Backlog Management',
            permissions: [
                { value: 'backlogViewer', label: 'View Backlogs' },
                { value: 'backlogWriter', label: 'Manage Backlogs' },
            ],
        },
        {
            label: 'Task Management',
            permissions: [
                { value: 'taskViewer', label: 'View Tasks' },
                { value: 'taskWriter', label: 'Manage Tasks' },
            ],
        },
        {
            label: 'QA Management',
            permissions: [
                { value: 'qualityAssuranceViewer', label: 'View Quality Assuarance' },
                { value: 'qualityAssuranceWriter', label: 'Manage Quality Assuarance' },
            ],
        },
        {
            label: 'Archive Management',
            permissions: [
                { value: 'archiveViewer', label: 'View Archives' },
                { value: 'archiveWriter', label: 'Manage Archives' },
            ],
        },
        {
            label: 'Departments Management',
            permissions: [
                { value: 'departmentViewer', label: 'View Departments' },
                { value: 'departmentWriter', label: 'Manage Departments' },
            ],
        },
        {
            label: 'User Management',
            permissions: [
                { value: 'userViewer', label: 'View Users' },
                { value: 'userWriter', label: 'Manage Users' },
            ],
        },
        {
            label: 'Other Permissions',
            permissions: [
                { value: 'executiveViewer', label: 'Executive View' },
            ],
        },
    ];

    // Initialize form data when userData changes
    useEffect(() => {
        if (userData) {
            setFormData({
                email: userData.email || '',
                first_name: userData.first_name || '',
                last_name: userData.last_name || '',
                departments: userData.departments?.map(dept => dept.id) || [],
                roles: userData.roles || [],
                status: userData.status || 'active'

            });
            setSelectedPermissions(userData.roles || []);
        }
    }, [userData]);

    useEffect(() => {
        if (isOpen) {
            fetchDepartments();
            setIsAnimating(true);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    console.log(userData);

    const fetchDepartments = async () => {
        try {
            const response = await api.get(`${api_url}/user/${id}/department`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setDepartments(response.data.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
            toast.error('Error in fetching departments');
        }
    };


    const handleDepartmentChange = (deptId) => {
        setFormData(prevData => ({
            ...prevData,
            departments: prevData.departments.includes(deptId)
                ? prevData.departments.filter(d => d !== deptId)
                : [...prevData.departments, deptId]
        }));
    };
    const handleStatusChange = (checked) => {
        setFormData(prevData => ({
            ...prevData,
            status: checked ? 'active' : 'inactive'
        }));
    };

    const getSelectedDepartmentNames = () => {
        return formData.departments
            .map(deptId => {
                const dept = departments.find(d => d.id === deptId);
                return dept ? dept.name : '';
            })
            .filter(Boolean)
            .join(', ');
    };

    if (!isOpen && !isAnimating) return null;

    const handleAnimationEnd = () => {
        if (!isOpen) setIsAnimating(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handlePermissionChange = (category, permission) => {
        setSelectedPermissions(prevPermissions => {
            if (prevPermissions.includes(permission)) {
                return prevPermissions.filter(p => p !== permission);
            } else {
                const otherCategoryPermissions = prevPermissions.filter(p =>
                    !category.permissions.some(catPerm => catPerm.value === p)
                );
                return [...otherCategoryPermissions, permission];
            }
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.first_name) newErrors.first_name = 'First name is required';
        if (!formData.last_name) newErrors.last_name = 'Last name is required';
        if (formData.departments.length === 0) newErrors.departments = 'At least one department is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true);
        try {
            const updatedUserData = { ...formData, roles: selectedPermissions };
            await api.put(`${api_url}/user/${id}/user-profile/${userData.id}/update`, updatedUserData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            toast.success('User updated successfully');
            fetchUser();
            onClose();
        } catch (error) {
            console.error('Error updating user:', error);
            toast.error('Error updating user');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='h-full' onClick={onClose}>
            <div
                className={`bg-white max-w-md h-full transform transition-all duration-300 ease-in-out overflow-auto ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}
                onClick={e => e.stopPropagation()}
                onTransitionEnd={handleAnimationEnd}
            >
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-base font-semibold">Edit User</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        ×
                    </button>
                </div>
                <form className='p-3 my-auto'>
                    <div className="mb-4">
                        <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">
                                Status
                            </label>
                            <div className="flex items-center space-x-2">
                                <Switch
                                    checked={formData.status === 'active'}
                                    onChange={handleStatusChange}
                                    className={`${formData.status === 'active' ? 'bg-green-500' : 'bg-gray-200'
                                        } relative inline-flex h-6 py-1 w-11 rounded-full transition-colors duration-200 ease-in-out focus:outline-none`}
                                >
                                    <span
                                        className={`${formData.status === 'active' ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out`}
                                    />
                                </Switch>
                                <span className="text-sm text-gray-500">
                                    {formData.status === 'active' ? 'Active' : 'Inactive'}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Enter Email"
                            className="w-full px-3 py-1 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none"
                            required
                        />
                        {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 mb-2">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleInputChange}
                            placeholder="Enter First Name"
                            className="w-full px-3 py-1 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none"
                            required
                        />
                        {errors.first_name && <p className="text-red-500 text-xs mt-1">{errors.first_name}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 mb-2">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleInputChange}
                            placeholder="Enter Last Name"
                            className="w-full px-3 py-1 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none"
                            required
                        />
                        {errors.last_name && <p className="text-red-500 text-xs mt-1">{errors.last_name}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Departments
                        </label>
                        <div className="relative">
                            <div
                                onClick={() => setIsDepartmentsOpen(!isDepartmentsOpen)}
                                className="w-full px-3 py-1 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none cursor-pointer flex justify-between items-center"
                            >
                                <span>
                                    {formData.departments.length
                                        ? getSelectedDepartmentNames()
                                        : 'Select Departments'}
                                </span>
                                <ChevronDown className="h-4 w-4" />
                            </div>
                            {isDepartmentsOpen && (
                                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-y-auto">
                                    {departments.map(dept => (
                                        <label
                                            key={dept.id}
                                            className="flex items-center px-3 py-1 hover:bg-gray-100 cursor-pointer"
                                        >
                                            <input
                                                type="checkbox"
                                                checked={formData.departments.includes(dept.id)}
                                                onChange={() => handleDepartmentChange(dept.id)}
                                                className="mr-2"
                                            />
                                            <span className="text-sm">{dept.name}</span>
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                        {errors.departments && <p className="text-red-500 text-xs mt-1">{errors.departments}</p>}
                    </div>
                    <div className="mb-8">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Permissions
                        </label>
                        <div className='grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4'>
                            {roles.map((category) => (
                                <div key={category.label} className='flex flex-col items-start'>
                                    <div>
                                        <p className='font-lufga'>{category.label}</p>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        {category.permissions.map((permission) => (
                                            <div key={permission.value} className='flex items-center gap-1'>
                                                <input
                                                    type="checkbox"
                                                    id={permission.value}
                                                    name={category.label}
                                                    value={permission.value}
                                                    checked={selectedPermissions.includes(permission.value)}
                                                    onChange={() => handlePermissionChange(category, permission.value)}
                                                    className="form-checkbox text-green-500"
                                                />
                                                <label htmlFor={permission.value} className='text-xs'>{permission.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-sm bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmit}
                            disabled={loading}
                            className="px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            {!loading ? 'Update User' : 'Loading...'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditUserForm;