import React, { useEffect, useState, useCallback } from 'react';
import { ImFileEmpty, ImSearch } from 'react-icons/im';
import { DNA } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import SubscriberVerificationModal from '../components/SubscriberVerificationModal';
import { useSelector } from 'react-redux';
import { api_url } from '../config';
import { toast } from 'react-toastify';
import api from '../utils/Api';
import debounce from 'lodash/debounce';
import { selectRoles } from '../Redux/AuthSlice';
import { hasPermission } from '../Redux/PermissionSlice';

const SubscribersPage = () => {
    // State Management
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subscriberToVerify, setSubscriberToVerify] = useState('');
    const [subscribers, setSubscribers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const selectedRoles = useSelector(selectRoles);
    const userRoles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());


    // Redux state
    const { id, token } = useSelector((state) => state.auth);

    // Pagination state
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        perPage: 15
    });

    // Navigation
    const navigate = useNavigate();

    // API Calls
    const fetchSubscribers = async (query = '') => {
        try {
            setLoading(true);
            const response = await api.get(`${api_url}/user/${id}/subscriber`, {
                params: {
                    page: pagination.currentPage,
                    limit: pagination.perPage,
                    search: query
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            setSubscribers(response.data.data || []);
            setPagination(prev => ({
                ...prev,
                currentPage: response.data.meta.current_page,
                lastPage: response.data.meta.last_page,
                total: response.data.meta.total,
                perPage: response.data.meta.per_page
            }));
        } catch (error) {
            console.error('Error fetching Subscribers:', error);
            toast.error('Error in fetching Subscribers');
        } finally {
            setLoading(false);
            setIsSearching(false);
        }
    };

    // Search Handlers
    const debouncedSearch = useCallback(
        debounce((query) => {
            setPagination(prev => ({ ...prev, currentPage: 1 }));
            fetchSubscribers(query);
        }, 500),
        []
    );

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        setIsSearching(true);
        debouncedSearch(query);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        setIsSearching(false);
        setPagination(prev => ({ ...prev, currentPage: 1 }));
        fetchSubscribers('');
    };

    // Pagination Handlers
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.lastPage) {
            setPagination(prev => ({
                ...prev,
                currentPage: newPage
            }));
        }
    };

    const getPageNumbers = () => {
        const currentPage = pagination.currentPage;
        const totalPages = pagination.lastPage;
        const maxVisiblePages = 5;

        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    const getItemRange = () => {
        const start = ((pagination.currentPage - 1) * pagination.perPage) + 1;
        const end = Math.min(pagination.currentPage * pagination.perPage, pagination.total);
        return { start, end };
    };

    // Navigation Handlers
    const viewSubscriberDetails = (data) => {
        navigate(`${data.id}`,{ 
            state: { 
                requiredRoles: ['subscriberAdmin','subscriberView','subscriberWriter'] 
            } 
        });
    };

    // Modal Handlers
    const handleOpenModal = (subscriber) => {
        setSubscriberToVerify(subscriber);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSubscriberToVerify('');
    };

    // Effects
    useEffect(() => {
        fetchSubscribers(searchQuery);
    }, [pagination.currentPage]);

    // Render Helpers
    const renderSubscriberCard = (item) => (
        <div key={item?.id} className="border rounded-lg shadow-lg p-4 bg-white">
            <div className='flex justify-center gap-2'>
                <img
                    src={`https://ui-avatars.com/api/?name=${item.company_name}&background=random`}
                    alt="Company Avatar"
                    className="h-10 w-10 rounded-full"
                />
                <div className="flex-1">
                    <h3 className="text-sm font-semibold">{item?.company_name}</h3>
                    <p className="text-xs font-semibold text-gray-500">
                        <span>Email:</span> {item?.email}
                    </p>
                    <p className="text-xs text-gray-500">
                        <span className="font-semibold">Address:</span> {item?.company_address}
                    </p>
                    <p className="text-xs text-gray-500">
                        <span className="font-semibold">Contact:</span> {item?.company_phone}
                    </p>
                </div>
            </div>


            {hasPermission(userRoles, ['subscriberWriter', 'subscriberAdmin']) && (
                <div className="flex justify-end space-x-2 mt-2">
                    <button
                        onClick={() => handleOpenModal(item)}
                        className="px-2 py-1 text-xs bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                    >
                        Verify
                    </button>
                    <button
                        onClick={() => viewSubscriberDetails(item)}
                        className="px-2 py-1 text-xs bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                    >
                        View
                    </button>
                </div>
            )}
        </div>
    );

    const renderPagination = () => (
        <div className="border-t border-gray-200 px-4 py-3 flex items-center justify-between">
            <div className="flex-1 flex justify-between items-center">
                <div>
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{getItemRange().start}</span>{' '}
                        to <span className="font-medium">{getItemRange().end}</span>{' '}
                        of <span className="font-medium">{pagination.total}</span> results
                    </p>
                </div>
                <div className="flex gap-1">
                    <button
                        onClick={() => handlePageChange(pagination.currentPage - 1)}
                        disabled={pagination.currentPage <= 1}
                        className="relative inline-flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Previous
                    </button>
                    {getPageNumbers().map((pageNum) => (
                        <button
                            key={pageNum}
                            onClick={() => handlePageChange(pageNum)}
                            className={`relative inline-flex items-center px-3 py-2 text-sm font-medium rounded-md ${pageNum === pagination.currentPage
                                    ? 'bg-blue-500 text-white'
                                    : 'text-gray-700 bg-white hover:bg-gray-50'
                                }`}
                        >
                            {pageNum}
                        </button>
                    ))}
                    <button
                        onClick={() => handlePageChange(pagination.currentPage + 1)}
                        disabled={pagination.currentPage >= pagination.lastPage}
                        className="relative inline-flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );

    // Main Render
    return (
        <div className='h-full'>
            {/* Loading Overlay */}
            {loading ? (
                <div className="flex justify-center items-center w-full h-full backdrop-blur-sm absolute top-0 left-0">
                    <DNA
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />
                </div>
            ) : (
                <div>
                    {/* Header with Search */}
                    <div className='flex justify-between items-center m-5'>
                        <p className='font-semibold text-base'>
                            Subscribers List
                            {searchQuery && ` - Search results for "${searchQuery}"`}
                        </p>
                        <div className='relative w-1/4'>
                            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                                <ImSearch className='text-gray-400' size={14} />
                            </div>
                            <input
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className='w-full pl-10 pr-10 border border-neutral-300 rounded-md p-2 text-xs'
                                placeholder='Search subscribers...'
                            />
                            {searchQuery && (
                                <button
                                    onClick={handleClearSearch}
                                    className='absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600'
                                >
                                    ×
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Content */}
                    {subscribers.length > 0 ? (
                        <div>
                            <div className='max-w-[2000px] p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 gap-3 2xl:place-content-center'>
                                {subscribers.map(renderSubscriberCard)}
                            </div>
                            {renderPagination()}
                        </div>
                    ) : (
                        <div className='w-full h-full flex justify-center items-center'>
                            <span className='flex flex-col items-center justify-center gap-3'>
                                <ImFileEmpty size={48} className='text-blue-600' />
                                <p className='text-xs'>
                                    {searchQuery
                                        ? `No subscribers found for "${searchQuery}"`
                                        : 'No subscribers yet.'}
                                </p>
                                {searchQuery && (
                                    <button
                                        onClick={handleClearSearch}
                                        className='text-blue-500 hover:text-blue-600 text-sm'
                                    >
                                        Clear search
                                    </button>
                                )}
                            </span>
                        </div>
                    )}

                    {/* Verification Modal */}
                    <SubscriberVerificationModal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        company={subscriberToVerify}
                    />
                </div>
            )}


        </div>
    );
};

export default SubscribersPage;