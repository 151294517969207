import axios from 'axios';
import React, { useState } from 'react';
import { FaRegEyeSlash } from 'react-icons/fa';
import { IoEyeOutline } from 'react-icons/io5';
import { DNA, InfinitySpin } from 'react-loader-spinner';
import { api_url } from '../config';
import { useSelector } from 'react-redux';
import api from '../utils/Api';
import { toast } from 'react-toastify';

const UpdatePasswordForm = ({ handleLogout }) => {

    const [status, setStatus] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [oldpassword, setOldpassword] = useState('')
    const [newpassword, setNewpassword] = useState('')
    const [password_confirmation, setPassword_confirmation] = useState('')
    const [newpasswordState, setNewpasswordState] = useState(false);
    const [oldpasswordState, setOldpasswordState] = useState(false);
    const [confirm_passwordState, setConfirm_passwordState] = useState(false)
    const { id, branch_id, token } = useSelector((state) => state.auth)

    const handleOldPasswordToggle = (e) => {
        e.preventDefault();
        setOldpasswordState(!oldpasswordState);
    };
    const handleNewPasswordToggle = (e) => {
        e.preventDefault();
        setNewpasswordState(!newpasswordState);
    };
    const handleConfirmPasswordToggle = (e) => {
        e.preventDefault();
        setConfirm_passwordState(!confirm_passwordState)
    }

    const handlePasswordUpdate = async () => {
        const password_details = {
            password: newpassword,
            password_confirmation,
        }
        const required = !newpassword || !password_confirmation
        if (required) {
            setError(`Please fill all required field`)
            setTimeout(() => {
                setError('');
            }, 6000);
            return
        }


        try {
            setLoading(!loading)
            await api.put(api_url + `/user/${id}/user-profile/update-password`, password_details, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            setStatus('Password Updated Successfully')
            setLoading(false)
            sessionStorage.setItem('reset_password', 1);
            toast('Login again with your updated password')

            setTimeout(() => {
                handleLogout()
            }, 1500);
        } catch (error) {
            console.log(error.message);
            setError(error?.response?.data?.error)
        } finally {
            setLoading(false)
            setTimeout(() => {
                setError('');
            }, 6000);
        }
    }

    return (
        <div className='max-w-2xl  overflow-auto sm:min-w-[400px]  mx-auto p-4'>
            <div className=' mb-6'>
                <p className='text-center font-semibold text-neutral-500'>Update User Password</p>

            </div>
            <form className="grid gap-4 mb-6 md:grid-cols-1">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <DNA
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                        />
                    </div>
                )}

                {/* <div>
                    <label
                        htmlFor='password'
                        className='block mb-2 text-sm font-medium text-gray-900 '
                    >
                        Old Password
                    </label>
                    <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                        <input
                            value={oldpassword}
                            onChange={(e) => setOldpassword(e.target.value)}
                            type={oldpasswordState ? 'text' : 'password'}
                            name='password'
                            placeholder='••••••••'
                            className='bg-gray-50 focus:outline-none w-full'
                            required
                        />
                        {oldpasswordState ? (
                            <IoEyeOutline size={20} onClick={handleOldPasswordToggle} />
                        ) : (
                            <FaRegEyeSlash size={20} onClick={handleOldPasswordToggle} />
                        )}
                    </div>
                </div> */}
                <div>
                    <label
                        htmlFor='password'
                        className='block mb-2 text-sm font-medium text-gray-900 '
                    >
                        New Password
                    </label>
                    <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                        <input
                            value={newpassword}
                            onChange={(e) => setNewpassword(e.target.value)}
                            type={newpasswordState ? 'text' : 'password'}
                            name='password'
                            placeholder='••••••••'
                            className='bg-gray-50 focus:outline-none w-full'
                            required
                        />
                        {newpasswordState ? (
                            <IoEyeOutline size={20} onClick={handleNewPasswordToggle} />
                        ) : (
                            <FaRegEyeSlash size={20} onClick={handleNewPasswordToggle} />
                        )}
                    </div>
                </div>
                <div>
                    <label
                        className='block mb-2 text-sm font-medium text-gray-900 '
                    >
                        Confirm New Password
                    </label>
                    <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                        <input
                            value={password_confirmation}
                            onChange={(e) => setPassword_confirmation(e.target.value)}
                            type={confirm_passwordState ? 'text' : 'password'}
                            name='confirm_password'
                            placeholder='••••••••'
                            className='bg-gray-50 focus:outline-none w-full'
                            required
                        />
                        {confirm_passwordState ? (
                            <IoEyeOutline size={20} onClick={handleConfirmPasswordToggle} />
                        ) : (
                            <FaRegEyeSlash size={20} onClick={handleConfirmPasswordToggle} />
                        )}
                    </div>
                </div>
            </form>
            <div className='flex justify-between items-center'>
                <button onClick={handlePasswordUpdate} className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Update Password</button>
            </div>
            <div className='mt-2'>
                {status &&
                    <div>
                        <p className="bg-green-300 rounded-md shadow-md px-2 py-1 text-xs text-center">{status}</p>
                    </div>
                }
                {error && (
                    <div>
                        <p className="bg-red-300 rounded-md shadow-md px-2 py-1 text-xs ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div>
                )}
            </div>

        </div>
    );
}

export default UpdatePasswordForm;
