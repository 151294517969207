import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { FcNext, FcPrevious } from 'react-icons/fc';
import { DNA } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthData } from '../Redux/AuthSlice';
import { toast } from 'react-toastify';
import api from './Api';
import { api_url, auth_api_url } from '../config';
import UpdatePasswordForm from '../components/UpdatePasswordForm';

const Layout = () => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth)
    const {token,reset_password }= useSelector((state) => state.auth)

    useEffect(() => {
        const handleResize = () => {
            const largeScreen = window.innerWidth >= 1024; 
            setIsLargeScreen(largeScreen);
            setIsSidebarVisible(largeScreen);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleLogout = async () => {
        setLoading(true)
        
        try {
                await api.post(auth_api_url+'/logout', {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            
            dispatch(clearAuthData());

            // Manipulate the browser's history to prevent going back to protected pages
            window.history.pushState(null, '', '/login');
            window.onpopstate = () => {
                window.history.pushState(null, '', '/login');
            };
            toast.success('Logout successful')
            navigate('/login', { replace: true });
            setLoading(false)
        } catch (error) {
            console.error('Logout error:', error);
            // Handle error if needed
        }
    };

    

    return (
        <div className="flex flex-col h-screen bg-slate-100">
              
            <div>
                <Header handleLogout={handleLogout}/>
            </div>
            <div className="flex flex-grow overflow-hidden relative">
                <div className={`
                    absolute top-0 left-0 h-full
                    transition-all duration-300 ease-in-out
                    ${isLargeScreen
                        ? (isSidebarVisible ? 'w-[250px]' : 'w-0')
                        : 'w-full'
                    }
                    ${isSidebarVisible ? 'translate-x-0' : '-translate-x-full'}
                    ${isLargeScreen ? '' : 'z-30'}
                    overflow-hidden bg-white lg:bg-transparent 
                `}>
                    <div className="w-full h-full p-4 font-lufga">
                        <Sidebar />
                    </div>
                </div>
                <div className={`
                    flex-grow overflow-auto transition-all duration-300 ease-in-out
                    ${isLargeScreen && isSidebarVisible ? 'ml-[200px] 2xl:ml-[250px]' : 'ml-0'}
                `}>
                    <div className="flex-grow overflow-auto px-2 h-full rounded-tl">
                        <Outlet />
                    </div>
                </div>
                <button
                    onClick={toggleSidebar}
                    className="fixed top-10 left-0 z-40 bg-blue-900 bg-opacity-10 backdrop-blur-xl
                        border border-white border-opacity-20 p-2 hover:bg-opacity-20 rounded-lg"
                >
                    {isSidebarVisible ? <FcPrevious size={20} /> : <FcNext size={20} />}
                </button>
            </div>
            {loading && (<div className="flex inset-0 justify-center items-center w-full h-full backdrop-blur-sm absolute top-0 left-0">
                <DNA
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                />
            </div>)}

            {(!reset_password || reset_password === '0') && (
                <div className="fixed inset-0 flex items-center justify-center bg-neutral-800 bg-opacity-100 z-50">
                    <div className="bg-white rounded-md min-h-max shadow-lg p-6">
                        <UpdatePasswordForm handleLogout={handleLogout} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Layout;