import React, { useEffect, useState } from 'react';
import Stepper from '../components/Stepper';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import PendingIssueView from '../components/PendingIssueView';
import InProgressIssueView from '../components/InProgressIssueView';
import ResolvedIssueVIew from '../components/ResolvedIssueVIew';
import { toast } from 'react-toastify';
import api from '../utils/Api';
import { api_url } from '../config';
import { useSelector } from 'react-redux';
import { DNA } from 'react-loader-spinner';
import { selectRoles } from '../Redux/AuthSlice';

const IssueDetailPage = () => {
    const navigate = useNavigate()
    const [backlog,setBacklog] = useState([])
    const [loading,setLoading] = useState(true)
    const [isSubmitting,setSubmitting] = useState(true)
    const{id,token} = useSelector((state)=>state.auth)
    const { id: backlogId } = useParams() 
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const selectedRoles = useSelector(selectRoles);
    const userRoles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());
    
   

    const fetchBacklogs = async () => {
        setLoading(true);
        try {
            const response = await api.get(`${api_url}/user/${id}/backlogs/${backlogId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setBacklog(response.data.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
            toast.error('Error in fetching departments')
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (issueStatus) => {
        setLoading(true);
        setError(null);

        try {
            const updatedIssue = {
                description:backlog?.description,
                department_id: backlog?.department,
                assignee_id: backlog?.assignee,
                app: backlog?.app,
                priority:backlog?.priority,
                status:issueStatus
            };

            
            const response = await api.put(`${api_url}/user/${id}/backlogs/${backlog.id}`, 
                updatedIssue,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            fetchBacklogs()

        } catch (err) {
            setLoading(false)
            setError('Failed to update issue. Please try again.');
            toast.error('Failed to update issue. Please try again.')
        } 
        // finally {
        //     setLoading(false);
        // }
    };


    useEffect(() => {
        fetchBacklogs();
    }, [id, token]);
    const renderStatusComponent = () => {
        switch (backlog?.status) {
            case 'pending':
              return <PendingIssueView issues={backlog} fetchBacklogs={fetchBacklogs} update={handleSubmit}/>;
            case 'in_progress':
              return <InProgressIssueView issues={backlog} update={handleSubmit} fetchBacklogs={fetchBacklogs}/>;
            case 'resolved':
              return <ResolvedIssueVIew issues={backlog} update={handleSubmit}/>;
            default:
              return <ResolvedIssueVIew issues={backlog} update={handleSubmit}/>;
          }
    };
    return (
      <div>
        {loading ? (
             <div className="flex justify-center items-center w-full h-full backdrop-blur-sm absolute top-0 left-0">
             <DNA visible={true} height="80" width="80" ariaLabel="dna-loading" />
         </div>
        ):(
            <div className="max-w-3xl mx-auto p-4 space-y-4">
            <div
                onClick={() => { navigate(-1) }}
                className="flex items-center gap-2 mb-6 cursor-pointer">
                <MdOutlineKeyboardBackspace
                    size={24} className="border rounded-full hover:scale-105 duration-150 ease-in " />
                <p className="text-base font-semibold ">Back</p>
            </div>
       
            <Stepper issues={backlog} />

            {renderStatusComponent()}
        </div>
        )}
      </div>
    );
}

export default IssueDetailPage;
