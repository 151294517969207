import React, { useEffect, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';
import { CgMore } from 'react-icons/cg';
import { FaChevronDown } from 'react-icons/fa';
import { ImFileEmpty } from 'react-icons/im';
import { DNA } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import AddUserModal from '../components/AddUserModal';
import { fetchUsers, setSelectedStatus, setCurrentPage, searchUsers, clearSearchResults } from '../Redux/UsersSlice';
import { useNavigate } from 'react-router-dom';
import { selectRoles } from '../Redux/AuthSlice';
import { hasPermission } from '../Redux/PermissionSlice';
import { useDebounce } from 'use-debounce';

const UsersPage = () => {
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm] = useDebounce(searchTerm, 100);

    const selectedRoles = useSelector(selectRoles);
    const userRoles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, token } = useSelector((state) => state.auth);
    const {
        users,
        searchResults,
        pagination,
        status: loadingStatus,
        searchStatus,
        selectedStatus
    } = useSelector((state) => state.users);

    const loading = loadingStatus === 'loading';
    const searching = searchStatus === 'loading';;

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.lastPage) {
            dispatch(setCurrentPage(newPage));
        }
    };

    const getPageNumbers = () => {
        const currentPage = pagination.currentPage;
        const totalPages = pagination.lastPage;
        const maxVisiblePages = 5;

        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    const getItemRange = () => {
        const start = ((pagination.currentPage - 1) * pagination.perPage) + 1;
        const end = Math.min(pagination.currentPage * pagination.perPage, pagination.total);
        return { start, end };
    };

    const handleStatusChange = (status) => {
        dispatch(setSelectedStatus(status));
        setStatusDropdownOpen(false);
    };

    useEffect(() => {
        if (!searchTerm) {
            dispatch(clearSearchResults());
        }
    }, [searchTerm, dispatch]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            dispatch(searchUsers({
                id,
                token,
                search: debouncedSearchTerm
            }));
        }
    }, [debouncedSearchTerm]);

    // Separate useEffect for regular fetching
    useEffect(() => {
        if (!searchTerm) {
            dispatch(fetchUsers({
                id,
                token,
                page: pagination.currentPage,
                limit: pagination.perPage,
                status: selectedStatus
            }));
        }
    }, [dispatch, id, token, pagination.currentPage, selectedStatus, searchTerm]);

    return (
        <div className="h-full flex flex-col">
            {loading ? (
                <div className="flex justify-center items-center w-full h-full backdrop-blur-sm absolute top-0 left-0">
                    <DNA
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperClass="dna-wrapper"
                    />
                </div>
            ) : (
                <div className="w-full h-full max-w-4xl mx-auto flex flex-col mb-10">
                    <div>
                        <div className="flex justify-between items-center m-5">
                            <div className="flex items-center gap-4 flex-1">
                                <p className="font-semibold text-base">Users</p>
                                <div className="relative flex-1 max-w-md">
                                    <input
                                        type="text"
                                        placeholder="Search users..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="w-full px-4 py-1.5 border border-gray-300 rounded-md text-xs focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    />
                                    {/* Search results positioned directly below input */}
                                    {searchTerm && (
                                        <div className="absolute left-0 right-0 top-full mt-1 bg-white rounded-md shadow-sm border border-gray-200 z-20">
                                            <div className="p-2">
                                                <p className="text-xs text-gray-500 mb-2">
                                                    {searching ? 'Searching...' : 'Search Results'}
                                                </p>
                                                <div className="max-h-40 overflow-y-auto">
                                                    {searching ? (
                                                        <div className="py-2 text-center text-sm text-gray-500">
                                                            Loading...
                                                        </div>
                                                    ) : searchResults.length > 0 ? (
                                                        searchResults.map((user) => (
                                                            <div
                                                                key={user.id}
                                                                onClick={() => navigate(`${user.id}`, {
                                                                    state: {
                                                                        requiredRoles: ['userAdmin', 'userView', 'userWriter']
                                                                    }
                                                                })}
                                                                className="px-3 py-2 hover:bg-gray-50 cursor-pointer border-b border-gray-100 last:border-0"
                                                            >
                                                                <div className="flex items-center justify-between">
                                                                    <div>
                                                                        <p className="text-sm font-medium text-gray-900">
                                                                            {`${user.first_name} ${user.last_name}`}
                                                                        </p>
                                                                        <p className="text-xs text-gray-500">
                                                                            ID: {user.id}
                                                                        </p>
                                                                    </div>
                                                                    <span className={`text-xs px-2 py-1 rounded-full ${
                                                                        user.status === 'active' ? 'bg-green-100 text-green-800' :
                                                                        user.status === 'inactive' ? 'bg-red-100 text-red-800' :
                                                                        'bg-gray-100 text-gray-800'
                                                                    }`}>
                                                                        {user.status}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="py-2 text-center text-sm text-gray-500">
                                                            No users found
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {hasPermission(userRoles, ['userWriter', 'userAdmin']) && (
                                <button
                                    onClick={() => setShowAddForm(true)}
                                    className="bg-blue-500 text-white text-sm py-1 px-4 rounded hover:bg-blue-600 transition-colors"
                                >
                                    Add User
                                </button>
                            )}
                        </div>
                    </div>
                    {users.length > 0 ? (
                        <div className="overflow-hidden bg-white shadow-md rounded-lg">
                            <div className="overflow-y-auto" style={{ maxHeight: 'calc(90vh - 200px)' }}>
                                <table className="w-full">
                                    <thead className="bg-gray-50 sticky top-0 z-10">
                                        <tr className="text-left">
                                            <th className="py-3 px-4 font-semibold text-sm text-gray-600">User ID</th>
                                            <th className="py-3 px-4 font-semibold text-sm text-gray-600">Name</th>
                                            <th className="py-3 px-4 font-semibold text-sm text-gray-600">Date Added</th>
                                            <th className="py-3 px-4 font-semibold text-sm text-gray-600 relative">
                                                <button
                                                    onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
                                                    className="flex items-center gap-1 focus:outline-none"
                                                >
                                                    Status
                                                    <FaChevronDown size={12} />
                                                </button>
                                                {statusDropdownOpen && (
                                                    <div className="absolute z-20 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                                        <div className="py-1">
                                                            {['all', 'active', 'inactive', 'archived'].map((status) => (
                                                                <button
                                                                    key={status}
                                                                    onClick={() => handleStatusChange(status)}
                                                                    className={`block px-4 py-2 text-xs w-full text-left hover:bg-gray-100 ${selectedStatus === status ? 'bg-gray-100' : ''}`}
                                                                >
                                                                    {status.charAt(0).toUpperCase() + status.slice(1)}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </th>
                                            <th className="py-3 px-4 font-semibold text-sm text-gray-600">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user, index) => (
                                            <tr key={user.id} className="border-t border-gray-200">
                                                <td className="py-2 px-4 text-xs">{user.id}</td>
                                                <td className="py-2 px-4 text-xs">{`${user.first_name} ${user.last_name}`}</td>
                                                <td className="py-2 px-4 text-xs">{new Date(user.created_at).toLocaleDateString()}</td>
                                                <td className="py-2 px-4 text-xs">{user.status}</td>
                                                <td className="py-2 px-4 text-xs">
                                                    <button
                                                        onClick={() => navigate(`${user.id}`, {
                                                            state: {
                                                                requiredRoles: ['userAdmin', 'userView', 'userWriter']
                                                            }
                                                        })}
                                                        className="flex w-full text-blue-500 items-center rounded px-3 py-1 text-xs hover:underline"
                                                    >
                                                        view
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-3 flex items-center justify-between">
                                <div className="flex-1 flex justify-between items-center">
                                    <div>
                                        <p className="text-sm text-gray-700">
                                            Showing <span className="font-medium">{getItemRange().start}</span>{' '}
                                            to <span className="font-medium">{getItemRange().end}</span>{' '}
                                            of <span className="font-medium">{pagination.total}</span> results
                                        </p>
                                    </div>
                                    <div className="flex gap-1">
                                        <button
                                            onClick={() => handlePageChange(pagination.currentPage - 1)}
                                            disabled={pagination.currentPage <= 1}
                                            className="relative inline-flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Previous
                                        </button>
                                        {getPageNumbers().map((pageNum) => (
                                            <button
                                                key={pageNum}
                                                onClick={() => handlePageChange(pageNum)}
                                                className={`relative inline-flex items-center px-3 py-2 text-sm font-medium rounded-md ${pageNum === pagination.currentPage
                                                    ? 'bg-blue-500 text-white'
                                                    : 'text-gray-700 bg-white hover:bg-gray-50'
                                                    }`}
                                            >
                                                {pageNum}
                                            </button>
                                        ))}
                                        <button
                                            onClick={() => handlePageChange(pagination.currentPage + 1)}
                                            disabled={pagination.currentPage >= pagination.lastPage}
                                            className="relative inline-flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full h-full flex justify-center items-center">
                            <span className="flex flex-col items-center justify-center gap-3">
                                <ImFileEmpty size={48} className="text-blue-600" />
                                <p className="text-xs">No Users yet.</p>
                                <button
                                    onClick={() => setShowAddForm(true)}
                                    className="bg-blue-500 text-white text-sm py-1 px-4 rounded hover:bg-blue-600 transition-colors"
                                >
                                    Add User
                                </button>
                            </span>
                        </div>
                    )}
                    <div className={`fixed top-0 right-0 h-full z-50 bg-white shadow-lg transform transition-transform duration-300 ${showAddForm ? 'translate-x-0' : 'translate-x-full'}`}>
                        <AddUserModal
                            isOpen={showAddForm}
                            onClose={() => setShowAddForm(false)}
                            fetchUsers={() => dispatch(fetchUsers({ id, token, page: pagination.currentPage, limit: pagination.perPage, status: selectedStatus }))}
                            token={token}
                            id={id}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default UsersPage;