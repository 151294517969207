import React from 'react';
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs';
import { GoShieldCheck } from 'react-icons/go';
import { HiOutlineUsers, HiOutlineViewGrid } from 'react-icons/hi';
import { IoArchiveOutline } from 'react-icons/io5';
import { LuFileSpreadsheet } from 'react-icons/lu';
import { MdOutlineTask, MdPayments } from 'react-icons/md';
import { RiBuildingLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import { hasPermission } from '../Redux/PermissionSlice';
import { selectRoles } from '../Redux/AuthSlice';
import { useSelector } from 'react-redux';

const Sidebar = () => {
    const location = useLocation();
    const getActiveMenuItem = (path) => {
        if (path.includes('/dashboard/overview')) return 'Overview';
        if (path.includes('/dashboard/subscribers')) return 'Subscribers';
        if (path.includes('/dashboard/backlogs')) return 'Backlogs';
        if (path.includes('/dashboard/tasks')) return 'My Tasks';
        if (path.includes('/dashboard/qa')) return 'Qa';
        if (path.includes('/dashboard/archive')) return 'Archive';
        if (path.includes('/dashboard/departments')) return 'Departments';
        if (path.includes('/dashboard/users')) return 'Users';
        return '';
    };

    const activeMenuItem = getActiveMenuItem(location.pathname);
    const selectedRoles = useSelector(selectRoles);
    const userRoles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());


    const MenuItem = ({ to, icon: Icon, label, state }) => (
        <Link to={to} state={state}>
            <div className={`flex items-center gap-2 p-2 hover:opacity-75 ${activeMenuItem === label ? 'text-blue-600' : 'text-neutral-700'}`}>
                <Icon size={20} />
                <button>{label}</button>
            </div>
        </Link>
    );

    return (
        <div>
            <div className='flex flex-col gap-2'>
                {/* <MenuItem to='overview' icon={HiOutlineViewGrid} label='Overview' /> */}
                
                {hasPermission(userRoles, ['subscriberViewr','subscriberWriter','subscriberAdmin']) && (
                   <MenuItem 
                   to='subscribers' 
                   icon={MdPayments} 
                   label='Subscribers' 
                   state={{ requiredRoles: ['subscriberViewer','subscriberWriter','subscriberAdmin'] }}
               />
                )}
                {hasPermission(userRoles, ['backlogViewer','backlogWriter','backlogAdmin']) && (
                  <MenuItem 
                  to='backlogs' 
                  icon={LuFileSpreadsheet} 
                  label='Backlogs' 
                  state={{ requiredRoles:['backlogViewer','backlogWriter','backlogAdmin']}}
              />
                )}
                {hasPermission(userRoles, ['taskViewer','taskWriter','taskAdmin']) && (
                   <MenuItem 
                   to='tasks' 
                   icon={MdOutlineTask} 
                   label='My Tasks' 
                   state={{ requiredRoles: ['taskViewer','taskWriter','taskAdmin'] }}
               />
                )}
                {hasPermission(userRoles, ['qualityAssuranceViewer','qualityAssuranceWriter','qualityAssuranceAdmin']) && (
                   <MenuItem 
                   to='qa' 
                   icon={GoShieldCheck} 
                   label='Qa' 
                   state={{ requiredRoles: ['qualityAssuranceViewer','qualityAssuranceWriter','qualityAssuranceAdmin'] }}
               />
                )}
                {hasPermission(userRoles, ['archiveViewer','archiveWriter','archiveAdmin']) && (
                    <MenuItem 
                    to='archive' 
                    icon={IoArchiveOutline} 
                    label='Archive' 
                    state={{ requiredRoles: ['archiveViewer','archiveWriter','archiveAdmin']}}
                />
                )}
                {hasPermission(userRoles, ['departmentViewer','departmentWriter','departmentAdmin']) && (
                    <MenuItem 
                    to='departments' 
                    icon={RiBuildingLine} 
                    label='Departments' 
                    state={{ requiredRoles: ['departmentViewer','departmentWriter','departmentAdmin'] }}
                />
                )}
                {hasPermission(userRoles, ['userViewer','userWriter','userAdmin']) && (
                    <MenuItem 
                    to='users' 
                    icon={HiOutlineUsers} 
                    label='Users' 
                    state={{ requiredRoles: ['userViewer','userWriter','userAdmin'] }}
                />
                )}
                
                
               
                
               
               
               
            </div>
        </div>
    );
}

export default Sidebar;