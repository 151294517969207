import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { DNA } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import UpdateSubscriberProfile from "./UpdateSubscriberProfile";
import AddIssueForm from "./AddIssueForm";
import SubscriptionsPerAccount from "./SubscriptionsPerAccount";
import { useDispatch, useSelector } from "react-redux";
import api from "../utils/Api";
import { api_url } from "../config";
import { toast } from "react-toastify";
import { fetchSubscriberBacklogs, selectAllBacklogs, selectBacklogStatus } from "../Redux/SubscriberBacklogSlice";

const SubscriberDetail = () => {
    const [loading, setLoading] = useState(true)
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const { token, id } = useSelector((state) => state.auth)
    const { subscriberId } = useParams()
    const status = useSelector(selectBacklogStatus);
    const info = useSelector(selectAllBacklogs)
    const dispatch = useDispatch()


 useEffect(() => {
        dispatch(fetchSubscriberBacklogs({
            userId: id,
            subscriberId: subscriberId,
            token: token
        }));
    }, [dispatch, id, subscriberId, token]);


   

    const navigate = useNavigate()
    return (
        <div >
            {status === 'loading' ?
                (<div className="flex justify-center items-center w-full h-full  backdrop-blur-sm absolute top-0 left-0">
                    <DNA
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />
                </div>) :
                <div className="container mx-auto p-6">
                    <div className="flex items-center gap-3 mb-6">
                        <MdOutlineKeyboardBackspace
                            onClick={() => { navigate(-1) }}
                            size={24} className="border rounded-full hover:scale-150 duration-150 ease-in cursor-pointer" />
                        <p className="text-2xl font-bold ">Subscriber Details</p>
                    </div>
                    {/* Grid Layout for Account Profile and Subscription Details */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Account Profile Section */}
                        <div className="bg-white shadow-md rounded-lg p-6 max-h-fit">
                            <h2 className="text-xl font-semibold mb-4">Account Profile</h2>
                            <div className="flex items-center gap-3 mb-4">
                                {/* Company Logo */}
                                <img
                                    src={`https://ui-avatars.com/api/?name=${info?.company_name}&background=random`}
                                    alt="Company Avatar"
                                    className="h-10 w-10 rounded-full"
                                />
                                <div>
                                    <p className="text-lg font-medium">{info?.company_name}</p>
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <div>
                                    <div className="mb-4">
                                        <p className="text-xs text-slate-500">Email</p>
                                        <p className="text-sm ">i{info?.email}</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="text-xs text-slate-500">Business Phone Number</p>
                                        <p className="text-sm ">{info?.company_phone}</p>
                                    </div>


                                </div>
                                <div>
                                    <div className="mb-4">
                                        <p className="text-xs text-slate-500">Address</p>
                                        <p className="text-sm ">{info?.company_address}</p>
                                    </div>

                                    <div className="mb-2">
                                        <p className="text-xs text-slate-500">Admin Name</p>
                                        <p className="text-sm ">{info?.admin}</p>
                                    </div>

                                </div>

                            </div>
                            {/* <div className="flex justify-end">
                                <button
                                onClick={()=>setIsProfileModalOpen(true)}
                                 className="mt-4 px-3 text-sm py-1 bg-blue-500 text-white rounded hover:bg-blue-600">
                                    Update Profile
                                </button>
                            </div> */}
                        </div>
                        <SubscriptionsPerAccount subscriptions={info?.subscriptions} />



                    </div>

                    {/* Issue Backlogs Section */}
                    <div className="bg-white shadow-md rounded-lg mt-6 p-6">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Issue Backlogs</h2>
                            <button
                                onClick={() => { setIsFormModalOpen(true) }}
                                className="mt-4 px-3 text-sm py-1 bg-blue-500 text-white rounded hover:bg-blue-600">
                                Add Issue
                            </button>
                        </div>

                        {/* Issues Table */}
                        <div className="overflow-x-auto shadow-lg rounded pb-2">
                            <table className="min-w-full table-auto text-xs">
                                <thead>
                                    <tr className="bg-gray-100 text-left">
                                        <th className="px-4 py-2">Date</th>
                                        <th className="px-4 py-2">Issue ID</th>
                                        <th className="px-4 py-2">Issue Details</th>
                                        <th className="px-4 py-2">Assignee</th>
                                        <th className="px-4 py-2">Department</th>
                                        <th className="px-4 py-2">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Issue Row Example */}
                                    {info?.backlogs?.map((item) => (
                                        <tr 
                                        key={item.id}
                                        className="border-b">
                                            <td className="px-4 py-2">{new Date(item?.created_at).toLocaleDateString()}</td>
                                            <td className="px-4 py-2">{item?.id}</td>
                                            <td className="px-4 py-2">{item?.description}</td>
                                            <td className="px-4 py-2">{item?.assignee}</td>
                                            <td className="px-4 py-2">{item?.department}</td>
                                            <td className="px-4 py-2 ">
                                               <p className={`
                                                    ${item?.status === "confirmed" ? "text-green-500 text-center bg-green-50 px-2 py-1 border border-green-200 rounded-lg"
                                                    : item?.status === "in_progress" ? "text-blue-500 text-center bg-blue-50 px-2 py-1 border border-blue-200 rounded-lg"
                                                    : item?.status === "pending" ? "text-yellow-500 text-center bg-yellow-50 pl-2 py-1 border border-yellow-200 rounded-lg"
                                                    : item?.status === "resolved" ? "text-purple-500 text-center bg-purple-50 px-2 py-1 border border-purple-200 rounded-lg"
                                                    : ""}`}
                                                >
                                                    {item?.status === "resolved" ? "Resolved"
                                                    : item?.status === "in_progress" ? "In Progress"
                                                    : item?.status === "pending" ? "Pending"
                                                    : item?.status === "confirmed" ? "Confirmed"
                                                    : ""}
                                                </p>
                                               </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        {/* <div className="flex justify-between items-center mt-4">
                            <p>5 of 20</p>
                            <div className="flex gap-2">
                                <button className="px-2 py-1 border rounded">1</button>
                                <button className="px-2 py-1 border rounded">2</button>
                                <button className="px-2 py-1 border rounded">3</button>
                                <button className="px-2 py-1 border rounded">4</button>
                                <button className="px-2 py-1 border rounded">5</button>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="bg-white shadow-md rounded-lg mt-6 p-6">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Purchase History</h2>

                        </div>

                        <div className="overflow-x-auto shadow-lg rounded pb-2">
                            <table className="min-w-full table-auto text-xs">
                                <thead>
                                    <tr className="bg-gray-100 text-left">
                                        <th className="px-4 py-2">Date</th>
                                        <th className="px-4 py-2">Description</th>
                                        <th className="px-4 py-2"></th>
                                        <th className="px-4 py-2">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-b">
                                        <td className="px-4 py-2">4/06/2024</td>
                                        <td className="px-4 py-2">Automatic order generation not triggering for low stock</td>
                                        <td className="px-4 py-2">Declined</td>
                                        <td className="px-4 py-2">$500</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="flex justify-between items-center mt-4">
                            <p>5 of 20</p>
                            <div className="flex gap-2">
                                <button className="px-2 py-1 border rounded">1</button>
                                <button className="px-2 py-1 border rounded">2</button>
                                <button className="px-2 py-1 border rounded">3</button>
                                <button className="px-2 py-1 border rounded">4</button>
                                <button className="px-2 py-1 border rounded">5</button>
                            </div>
                        </div>
                    </div> */}
                </div>}

            <UpdateSubscriberProfile
                isOpen={isProfileModalOpen}
                onClose={() => setIsProfileModalOpen(false)}
            />
            <AddIssueForm
                isOpen={isFormModalOpen}
                onClose={() => setIsFormModalOpen(false)}
            />
        </div>
    );
};

export default SubscriberDetail;
