import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { api_url } from '../config';
import api from '../utils/Api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import { selectRoles } from '../Redux/AuthSlice';
import { hasPermission } from '../Redux/PermissionSlice';

const IssueDetailsModal = ({ isOpen, onClose, issue, fetchIssues }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [loading, setLoading] = useState(false)

    const location = useLocation()
    const isValid = location.pathname.includes('qa')
    const { token, id } = useSelector((state) => state.auth)
    const selectedRoles = useSelector(selectRoles);
    const userRoles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());


    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    if (!isOpen && !isAnimating) return null;

    const handleAnimationEnd = () => {
        if (!isOpen) setIsAnimating(false);
    };
    const handleSubmit = async (issueStatus) => {
        setLoading(true);

        try {
            const updatedIssue = {
                description: issue?.description,
                department_id: issue?.department,
                assignee_id: issue?.assignee,
                app: issue?.app,
                priority: issue?.priority,
                status: issueStatus
            };


            const response = await api.put(`${api_url}/user/${id}/backlogs/${issue.id}`,
                updatedIssue,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            toast.success('Updated successfully')
            fetchIssues()
            onClose()

        } catch (err) {
            setLoading(false)
            toast.error('Failed to update issue. Please try again.')
        } finally {
            setLoading(false)
        }

    };


    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 flex p-8 justify-center items-center z-50 transition-opacity duration-300 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'}`}
            onClick={onClose}
        >
            <div
                className={`bg-white rounded-lg w-full max-w-md max-h-full transform transition-all duration-300 ease-in-out overflow-auto ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}
                onClick={e => e.stopPropagation()}
                onTransitionEnd={handleAnimationEnd}
            >
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-base font-semibold">Issue Details</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        ×
                    </button>
                </div>
                {issue && (
                    <div className="p-4">
                        <div className="mb-4">
                            <div className="font-semibold mb-2">Status</div>
                            <div className="flex items-center">
                                <span className={`w-2 h-2 rounded-full ${issue.status == 'confirmed' ? 'bg-green-500' : 'bg-purple-500'} mr-2`}></span>
                                <span className={`${issue.status == 'confirmed' ? 'text-green-500' : 'text-purple-500'}`}>{issue.status}</span>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div>
                                <div className="text-xs text-gray-500">Contact Name</div>
                                <div className='text-sm'>{issue.subscriber}</div>
                            </div>
                            <div>
                                <div className="text-xs text-gray-500">Product Name</div>
                                <div className='text-sm'>{issue.app}</div>
                            </div>

                            <div>
                                <div className="text-xs text-gray-500">Assignee</div>
                                <div className='text-sm'>{issue.assignee?.name}</div>
                            </div>
                            <div>
                                <div className="text-xs text-gray-500">Department</div>
                                <div className='text-sm'>{issue.department?.name}</div>
                            </div>
                            <div>
                                <div className="text-xs text-gray-500">Created By</div>
                                <div className='text-sm'>{issue.creator?.name}</div>
                            </div>
                            <div>
                                <div className="text-xs text-gray-500">Date Created</div>
                                <div className='text-sm'>{new Date(issue?.created_at).toLocaleDateString()}</div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="font-semibold mb-2 text-base">Issue Details</div>
                            <div className="bg-gray-100 p-2 rounded">
                                <p className='text-sm'>{issue.description}</p>
                            </div>
                        </div>
                        {/* <div>
                            <div className="font-semibold mb-2 text-base">Notes</div>
                            <div className="bg-gray-100 p-2 rounded">
                                <p className='text-sm'>
                                    Investigated the system crash and found that it occurs due to memory overload during bulk updates. Need to optimize the bulk update process.
                                </p>
                            </div>
                        </div> */}

                        <div className="bg-gray-100 p-2 rounded mt-2">
                            <strong className="text-xs text-gray-500">Notes</strong>
                            {/* <p className="text-sm mt-1">{issues?.description}</p> */}
                            {issue?.notes?.map((note) => (
                                <div key={note.id} className='my-2' >
                                    <p className="text-sm ">{note?.note}</p>
                                    <span className='flex items-center text-xs'>created by<p className=" text-gray-500">: {note?.name}</p></span>
                                </div>
                            ))}
                        </div>

                        {isValid && <div>
                            {loading ? (
                                <div className='flex justify-end mt-2'>
                                    <ColorRing
                                        visible={true}
                                        height="30"
                                        width="30"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    />
                                </div>
                            ) : (


                                <div>
                                    {hasPermission(userRoles, ['qualityAssuranceWriter', 'qualityAssuranceAdmin']) && (
                                        <div className='flex items-center justify-end gap-2 mt-2'>

                                            <button
                                                onClick={() => { handleSubmit('in_progress') }}
                                                className="bg-gray-300 text-xs text-black px-2 py-1 rounded hover:bg-gray-400 transition-colors">
                                                Mark As Unresolved
                                            </button>
                                            <button
                                                onClick={() => { handleSubmit('confirmed') }}
                                                className="bg-blue-500 text-xs text-white px-2 py-1 rounded hover:bg-blue-600 transition-colors">
                                                Confirm
                                            </button>
                                        </div>
                                    )}

                                </div>
                            )}
                        </div>}
                    </div>
                )}
            </div>
        </div>
    );
}

export default IssueDetailsModal;
