import React, { useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';

const SubscriptionsPerAccount = ({subscriptions}) => {
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [isTransitioning, setIsTransitioning] = useState(false);

 

    const handleSelectSubscription = (sub) => {
        setIsTransitioning(true);
        setTimeout(() => {
            setSelectedSubscription(sub);
            setIsTransitioning(false);
        }, 300);
    };

    const handleBackToList = () => {
        setIsTransitioning(true);
        setTimeout(() => {
            setSelectedSubscription(null);
            setIsTransitioning(false);
        }, 300);
    };

    const PackageList = () => (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {subscriptions.map((sub) => (
                <button
                    key={sub.id}
                    className="p-6 bg-white hover:bg-gray-50 border border-gray-200 rounded-xl text-left transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onClick={() => handleSelectSubscription(sub)}
                >
                    <h2 className="font-bold text-sm text-gray-800 mb-2">{sub?.app}</h2>
                    <p className="text-xs text-gray-600">${sub?.plan_price}/{sub?.billable}</p>
                </button>
            ))}
        </div>
    );

    const PackageDetails = ({ subscription }) => {
        // Define the fields we want to show
        const fieldsToShow = [
            'app',
            'plan_price',
            'billable',
            'subscription_status',
            'users_limit',
            'branches_limit',
            'sub_code',
            'expires_on',
            'created_at'
        ];

        const formatValue = (field, value) => {
            if (field === 'plan_price') return `$${value}`;
            if (field === 'expires_on' || field === 'created_at') {
                return new Date(value).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                });
            }
            return value;
        };

        return (
            <div className="px-5">
                <button 
                    className="mb-2 flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200 focus:outline-none"
                    onClick={handleBackToList}
                >
                    <BsArrowLeft size={24} className="mr-2" />
                    <span className="text-xs">Back to Packages</span>
                </button>
                <h2 className="text-xl font-bold mb-4 text-gray-800">{subscription?.app}</h2>
                <div className="space-y-2">
                    {fieldsToShow.map((field) => (
                        <div key={field} className="flex justify-between items-center py-2 border-b border-gray-100 text-sm">
                            <span className="text-gray-600 capitalize">{field.replace(/_/g, ' ')}:</span>
                            <span className="font-semibold text-gray-800">
                                {formatValue(field, subscription[field])}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="max-w-4xl mx-auto p-4 rounded-lg shadow-md bg-white w-full">
            <h1 className="text-sm font-bold text-center text-gray-800 mb-2">Your Subscriptions</h1>
            {subscriptions?.length > 0 ?<div className={`transition-opacity duration-300 ease-in-out ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
                {selectedSubscription ? (
                    <PackageDetails subscription={selectedSubscription} />
                ) : (
                    <PackageList />
                )}
            </div>: 
            <div className='h-full w-full flex justify-center items-center'>
                <p className='text-gray-400'>No subscriptions found</p>
                </div>}
        </div>
    );
};

export default SubscriptionsPerAccount;